import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import {FormsModule } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HeaderComponent } from './components/layout/header/header.component';
import { LoginComponent } from './components/login/login.component';
import { NotesComponent } from './components/notes/notes.component';
import { NoteComponent } from './components/note/note.component';

import { AlertModule } from 'ngx-bootstrap';
import { AddNoteComponent } from './components/add-note/add-note.component';
import { SharedNotesComponent } from './components/shared-notes/shared-notes.component';
import { EditNoteComponent } from './components/edit-note/edit-note.component';
import { PublicComponent } from './components/public/public.component';
import { SettingsComponent } from './components/settings/settings.component';
import { ColorPickerModule } from 'ngx-color-picker';

//bootstrap
import { BsDropdownModule } from 'ngx-bootstrap/dropdown';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { ModalModule } from 'ngx-bootstrap/modal';
// Font Awesome
import { FontAwesomeModule, FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { FooterComponent } from './components/layout/footer/footer.component';
import { PasswordFieldComponent } from './components/password-field/password-field.component';
import { AutofocusDirective } from './autofocus.directive';
import { faUser, faUsers, faCog, faSignOutAlt } from '@fortawesome/free-solid-svg-icons';


@NgModule({
  declarations: [
    AppComponent,
    HeaderComponent,
    LoginComponent,
    NotesComponent,
    NoteComponent,
    AddNoteComponent,
    SharedNotesComponent,
    EditNoteComponent,
    PublicComponent,
    SettingsComponent,
    FooterComponent,
    PasswordFieldComponent,
    AutofocusDirective
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    FormsModule,
    AlertModule.forRoot(),
    ColorPickerModule,
    BsDropdownModule.forRoot(),
    TooltipModule.forRoot(),
    ModalModule.forRoot(),
    FontAwesomeModule
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { 
  constructor(library: FaIconLibrary) {
    library.addIcons(faUser, faUsers, faCog, faSignOutAlt);
  }
}
