import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { FeathersService } from '../../services/feathers.service';
import { SortOptionsService } from '../../services/sort-options.service';

@Component({
  selector: 'app-settings',
  templateUrl: './settings.component.html',
  styleUrls: ['./settings.component.css']
})
export class SettingsComponent implements OnInit {

	email: string;
  defaultSort: number;

	message: string;

  sortOptionsSettings: any;

  constructor(private feathersService: FeathersService, private sortOptions: SortOptionsService, private router: Router) {
  	feathersService.authenticate()
  	.then( () => {
      this.sortOptionsSettings = this.sortOptions.getOptions();
  	})
    .catch( () => {
  		this.router.navigate(['login']);
  	})
   }

  ngOnInit() {
  }


  changePassword() {
   	//TO-DO: send request to change password
   	this.feathersService.service('authManagement').create({
      "action": "sendResetPwd",
		  "value": { "email": this.email}
	  })
   	this.message = 'Email was sent to ' + this.email;
  }

  updateSort(value) {
    //console.log(this.sortOptions.resolveValueToSetting(value));
    //TO-DO: save this settiing to back-end
    //this.feathersService.service('settings').patch(userId, {defaultSort: value});
    this.sortOptions.setDefaultSort(value);
  }  

}
