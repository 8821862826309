import { Component, OnInit } from '@angular/core';
import { FeathersService } from '../../services/feathers.service';

@Component({
  selector: 'app-shared-notes',
  templateUrl: './shared-notes.component.html',
  styleUrls: ['./shared-notes.component.css']
})
export class SharedNotesComponent implements OnInit {
	notes: object[];
  constructor(private feathersService: FeathersService) {
  	this.feathersService.authenticate()
  	.then( () => {
  		return this.feathersService.service('notes').find();
  	})
  	.then( data => {
  		this.notes = data.data;
  	})
  }

  ngOnInit() {
  }

}
