import { Injectable } from '@angular/core';
import * as Feathers from '@feathersjs/feathers';
import * as Rest from '@feathersjs/rest-client';
//import * as Authentication from '@feathersjs/authentication-client';

@Injectable({
  providedIn: 'root'
})
export class FeathersService {

  private _feathers: any;
  private _rest: any;
  //TO-DO: Fix this require to import at the start of the file
  private _authClient = require('@feathersjs/authentication-client').default;

  constructor() { 
  	this._feathers = Feathers();
  	this._rest = Rest();

  	this._feathers.configure(this._rest.fetch(window.fetch));
    this._feathers.configure(this._authClient({ storage: window.localStorage }));

  }

  public service(name:string) {
  	return this._feathers.service(name);
  }

  public authenticate(credentials?): Promise<any> {
  	return this._feathers.authenticate(credentials);
  }

  public logout() {
  	return this._feathers.logout();
  }

  public get(name:string) {
    return this._feathers.get(name);
  }
}
