import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { FeathersService } from '../../services/feathers.service';
import { SortOptionsService } from '../../services/sort-options.service';

import { Note } from '../../models/Note';

@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.css']
})
export class NotesComponent implements OnInit {
	notes: Note[];

  page: number = 0;
  total: number;
  limit: number = 10;

  loadAddNote: boolean = false;
  loading: boolean = false;

  filter: string = '';

  sort: any = {$sort: {updatedAt: -1}};
  sortOptionsSettings: any;


  constructor(private feathersService: FeathersService, private sortOptions: SortOptionsService, private router: Router) {
    this.loading = true;
    this.feathersService.authenticate()
    .then(() => {
      this.sortOptionsSettings = this.sortOptions.getOptions()
      return this.sortOptions.getDefaultSort();
    })
    .then( sort => {
      this.sort = sort;
      //console.log(this.sort);
      return this.feathersService.service('notes').find({ query: this.sort })
    })
    .then(data => {
      //console.log('got notes');
  		this.notes = data.data;
      this.total = data.total;
      this.limit = data.limit;
      this.loading = false;
  		//console.log(this.notes);
  	})
    .catch((error) => {
      console.log('some error', error)
      this.loading = false;
      this.router.navigate(['login']);
    });
  }


  ngOnInit() {
  }

  deleteNote(note:Note) {
    if( confirm("Are you sure to delete " + note.title)){
      this.feathersService.service('notes').remove(note._id, note)
      .then( () => {
        this.notes = this.notes.filter(t => t._id != note._id);
      })
    }
  }

  addNote(note:Note) {
    //console.log('adding', note);
    this.feathersService.service('notes').create(note)
    .then((data) => {
      console.log('adding new note', data);
      this.notes.unshift(data);
      this.loadAddNote = false;
    })
  }

  pagePrev() {
    this.page--;
    if (this.page < 0) {
      this.page = 0;
    } else {
      this.updateData({query: {$skip: this.page*this.limit}});
    }
  }

  pageNext() {
    this.page++;
    if(this.page*this.limit > this.total) {
      this.page--;
    }
    this.updateData({query: {$skip: this.page*this.limit}});
  }

  updateData(options = {}) {
    Object.assign(options['query'], this.sort);
    //console.log('updateding', options);
    this.feathersService.service('notes').find(options)
    .then(data => {
      //console.log(data);
      this.total = data.total;
      this.notes = data.data;
      this.limit = data.limit;
    })
    .catch (err => { console.log(err)})
  }

  updateNote(note) {
    //console.log('update',note);
    this.feathersService.service('notes').patch(note._id, note)
    .then( (data) => {
      //TO-DO: update notes array
      this.notes = this.notes.map(nt => { if(nt._id == data._id){return data}return nt});
      //console.log(data);
    })
    .catch( error => {
      console.log('error updating notes', error);
    })
  }

  onLoadAddNote() {
    this.loadAddNote = true;
  }

  closeModal() {
    this.loadAddNote = false;
  }

  applyFilter() {
    return alert("This feature is not yet implemented!");
    console.log('filter', this.filter);
     this.notes = this.notes.filter((note) => { return (note.title.includes(this.filter) || note.content.includes(this.filter)) });

     const options = {query: {$or: [ {title : {$regex : '.*'+ this.filter +'.*'}}, {content: {$regex: '.*' + this.filter + '.*'}}]}};
     this.updateData(options);
  }

  updateSort(value) {
    let query = this.sortOptions.resolveValueToSetting(value);
    this.sort = query;
    this.updateData({query});
  }

}
