import { Component, OnInit } from '@angular/core';
import { FeathersService } from '../../../services/feathers.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent implements OnInit {

  title:string = 'Sticky Notes';
  search: String;
  useremail: String;
  
  constructor(private feathersService: FeathersService, private router: Router) {
  }

  ngOnInit() {
    this.feathersService.get('authentication')
    .then( data => {
      //console.log(data);
      this.useremail = data.user.email;
    })
    .catch( err => {
      console.log(err);
    })
  }

  logout() {
  	this.feathersService.logout()
	  this.router.navigate(['/login']);
  }

}
