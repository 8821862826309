import { Component, OnInit } from '@angular/core';

import { FeathersService} from '../../services/feathers.service';

import { Note } from '../../models/Note';

@Component({
  selector: 'app-public',
  templateUrl: './public.component.html',
  styleUrls: ['./public.component.css']
})
export class PublicComponent implements OnInit {
	noteid: string;
	note:Note;
	message: string;

  constructor(private feathersService: FeathersService) { 
  	const urlParams = new URLSearchParams(window.location.search);
	this.noteid = urlParams.get('note');
	this.feathersService.service('notes').get(this.noteid)
	.then( (data) => {
		this.note = data;
		this.message = '';
	})
	.catch( (error) => {
		console.log('some error', error);
		this.message = 'An error occured. You probably do not have permitions to access this note, or it does not exists.';
	})
  }

  ngOnInit() {
  }

}
