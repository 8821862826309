import { Component, OnInit, Input, EventEmitter, Output, Renderer2 } from '@angular/core';
import { FeathersService} from '../../services/feathers.service';
import { paramsForServer } from 'feathers-hooks-common';

import { Note } from '../../models/Note';

@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.css']
})
export class NoteComponent implements OnInit {
  @Input() note;
  @Output() deleteNote: EventEmitter<Object> = new EventEmitter();
  @Output() updateNote: EventEmitter<Object> = new EventEmitter();
  
  loadEditNote: boolean = false;
  passwordRequired: boolean = false;
  error: string = '';
  tempNote: Note;
  loading: boolean = false;
  
  hideContentStyle: any = {
    'max-height.px': '100',
    'overflow': 'hidden'
  };
  showContentStyle: any = {
    'max-height': 'initial',
    'overflow': 'unset'
  };
  contentStyle: any = this.hideContentStyle;
  hiddenContent: boolean = true;
  longContent: boolean = false;
  
  constructor( private feathersService: FeathersService, private renderer: Renderer2) {  }


  ngOnInit() {
    this.tempNote = this.note;
    this.longContent = this.isContentLong();
  }

  onDelete(note) {
  	this.deleteNote.emit(note);
  }

  editNote(note) {
    if(this.note.protected) {
      this.passwordRequired = true;
    } else {
      this.openModal();
    }
  }

  saveNote() {
    this.updateNote.emit(this.note);
    this.longContent = this.isContentLong();
    this.closeModal();
  }

  addUser(user) {
    this.feathersService.service('users').find({query: {'email': user}})
    .then( (data) => {
      if(data.data.length != 0){
        this.note.access.push({'_id': data.data[0]._id, 'email': data.data[0].email});
      }
    })
  }

  openModal() {
    this.loadEditNote = true;
    this.renderer.addClass(document.body, 'overflow-hidden');
  }

  closeModal() {
    this.note = this.tempNote;
    this.loadEditNote = false;
    this.renderer.removeClass(document.body, 'overflow-hidden');
  }

  closeModalPassword() {
    this.passwordRequired = false;
    this.error = '';
    this.renderer.removeClass(document.body, 'overflow-hidden');
  }

  requestContent(password) {
    this.error = '';
    this.loading = true;
    this.feathersService.service('notes').get(this.note._id, paramsForServer({password}))
      .then( data => {
        this.note = data;
        this.closeModalPassword();
        this.openModal();
        this.loading = false;
      })
      .catch( err => {
        this.error = 'Invalid password!';
        this.loading = false;
      })
    }

  toggleContent() {
    //let button = document.getElementById('toggleContent');
    if(this.hiddenContent) {
      this.contentStyle = this.showContentStyle;
      //button.innerHTML = 'Collapse';
    } else {
      this.contentStyle = this.hideContentStyle;
      //button.innerHTML = 'Show All';
    }
    this.hiddenContent = !this.hiddenContent;
  }

  isContentLong() {
    if([...this.note.content.matchAll(/(\r\n|\r|\n)/g)].length > 4) {
      return true;
    } else {
      return false;
    }
  }

}
