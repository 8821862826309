import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-password-field',
  templateUrl: './password-field.component.html',
  styleUrls: ['./password-field.component.css']
})
export class PasswordFieldComponent implements OnInit {
  @Output() requirePassword: EventEmitter<Object> = new EventEmitter();

	password: string = '';

  constructor() { }

  ngOnInit() {
  }

  sendPassword() {
  	this.requirePassword.emit(this.password);
  }

  handleKeyUp(event) {
    if (event.keyCode == 13) {
      this.sendPassword();
    }
  }

}
