import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from '../../services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {
  //to make variable binding work dont forget to import FormsModule from @angular/forms in app.module.ts
  //this is the error: Can't bind to 'ngModel' since it isn't a known property of 'input'
  email:string;
  password:string;

  constructor(private authService:AuthService, private router: Router) { }

  ngOnInit() {
  }

  Login() {
  	const credentials = {
  		strategy: 'local',
  		email: this.email,
  		password: this.password
  	}
  	this.authService.logIn(credentials)
  	.then(() => {
  		console.log('success');
      this.router.navigate(['/']);
  	})
  	.catch(() => {
  		console.log('error');
  	})
  }

}
