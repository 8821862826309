import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

import { Note} from '../../models/Note';
@Component({
  selector: 'app-edit-note',
  templateUrl: './edit-note.component.html',
  styleUrls: ['./edit-note.component.css']
})
export class EditNoteComponent implements OnInit {
  @Input() note: Note;
  @Output() editNote: EventEmitter<any> = new EventEmitter();
  @Output() addUsr: EventEmitter<any> = new EventEmitter();

  user: string;
  constructor() { }

  ngOnInit() {
  }

  removeUser(user) {
  	this.note.access = this.note.access.filter(t => t.email != user.email);
  }

  addUser(){
    if (this.note.access.filter(t => t.email == this.user).length == 0) {
      this.addUsr.emit(this.user);
    }
  }
}
