import { Injectable } from '@angular/core';
import { FeathersService } from './feathers.service';

@Injectable({
  providedIn: 'root'
})
export class SortOptionsService {

  private _options = [
    {value: "1", displayName: "Last modified first", setting: { updatedAt: -1}},
    {value: "2", displayName: "Last modified last", setting: { updatedAt: 1}},
    {value: "3", displayName: "First created first", setting: { createdAt: 1}},
    {value: "4", displayName: "First created last", setting: { createdAt: -1}},
    {value: "5", displayName: "Title ascending", setting: { title: 1}},
    {value: "6", displayName: " Title descending", setting: { title: -1}}
  ]

  private defaultSort: any;
  private defaultSortValue: number;
  private data: any;

  constructor(private feathersService: FeathersService) { 
    //console.log('setting')
    feathersService.authenticate()
    .then( () => {
      return this.feathersService.service('user-settings').find()
    })
    .then( resp => {
      //console.log('got settings')
      //console.log(resp);
      if(resp.data.length == 1) {
        this.updateData(resp.data[0]);
      } else {
        console.log('no record found');
      }
    })
    .catch( () => {})
  }

  private updateData(data) {
    this.data = data;
    this.defaultSort = this.resolveValueToSetting(data.defaultSort);
    this.defaultSortValue = data.defaultSort;
  }

  public getOptions(options = {}) {
    //TO-DO: options allows to specify which fields should be returned
    return this._options.map((opt) => {return { displayName: opt['displayName'], value: opt['value']}});
  }

  public getDefaultSort() {
      return new Promise((resolve, reject) => {
        if (this.defaultSort) {
          //console.log('setting populated')
          resolve(this.defaultSort)
        } else {
          this.feathersService.service('user-settings').find()
          .then(resp => {
            //console.log('got settings')
            //console.log(resp);
              if(resp.data.length == 1) {
                this.updateData(resp.data[0]);
                //console.log('sort;', this.defaultSort)
                resolve(this.defaultSort);
              } else {
                //console.log('no record found');
                reject(1);
              }
          })
        }

      });
      //console.log('after wait', this.defaultSort)
    /*
     return this.feathersService.service('user-settings').find()
      .then( resp => {
        console.log('got settings')
        console.log(resp);
        if(resp.data.length == 1) {
          this.updateData(resp.data[0]);
          //this.data = resp.data[0];
          //console.log(this.data);
          //this.defaultSort = this.resolveValueToSetting(resp.data[0].defaultSort);
          //this.defaultSortValue = resp.data[0].defaultSort;
        } else {
          console.log('no record found');
        }
      return this.defaultSort;
    })*/
  }

  public getDefaultSortValue() {
    return this.defaultSortValue;
  }

  public setDefaultSort(value) {
    this.feathersService.service('user-settings').patch(this.data._id, {defaultSort: value})
    .then( data => {
      //console.log('Default sorting stored!', data);
      this.updateData(data);
    })
    .catch ( err => {
      console.log(err);
    })
  }

  public resolveValueToSetting(value) {
    //TO-DO: check if value is number
    for(let i = 0; i < this._options.length; i++) {
      if (this._options[i].value == value)
        return {$sort: this._options[i].setting}
    }
    return {};

    //return this._options.forEach((opt) => { if (opt.value == value) return {$sort: opt['setting']}});
    //return this._options.find((opt) => { if (opt.value == value) return {$sort: opt['setting']}});
  }
}
