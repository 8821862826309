import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-add-note',
  templateUrl: './add-note.component.html',
  styleUrls: ['./add-note.component.css']
})
export class AddNoteComponent implements OnInit {
	@Output() addNote: EventEmitter<any> = new EventEmitter();
	
  message: string = '';
	note = {
		owner: "",
		access: [],
		title: "",
		content: "",
		color: '#ffffff',
		public: false
	}

  constructor() {  }

  onSubmit() {
  	const note = this.note;
    if(note.content != ''){
    	console.log(note);
    	this.addNote.emit(note);
    } else {
      this.message = 'Sticky Note should have some text!';
    }
  }

  ngOnInit() {
  }

}
